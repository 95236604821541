import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { ArrowLeft, Menu, User } from "lucide-react";

import { useNavItemsStore } from "@/store";
import DesktopNavItemsSV from "./desktop-nav-items-sv";
import MobileNavItemsSV from "./mobile-nav-items-sv";
import ExpiryContainerSV from "./expiry-container-sv";
import { removeToken } from "@/utils/auth-token";
import Logo from "./logo";
import { getWishlist } from "@/services/wishlist/wishlist";
import useAppContext from "@/hooks/useAppContext";

const HomeBrownButton = dynamic(
  () => import("../ui/custom/home-brown-button"),
  {
    ssr: false,
  }
);
const UserDropDown = dynamic(() => import("../ui/custom/user-drop-down-sv"), {
  ssr: false,
});

function NewHeaderSV({ pageType, needKey }) {
  const router = useRouter();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const {wishlistCount, setWishlistCount} = useAppContext();
  const [showDropDown, setShowDropDown] = useState([
    {
      id: 1,
      title: "Invitation",
      isShow: false,
    },
    {
      id: 2,
      title: "Services",
      isShow: false,
    },
  ]);

  const navItems = useNavItemsStore((state) => state.navItems);
  const {
    initNavItems,
    showLogin,
    logoUrlRedirect,
    daysLeft,
    trialDate,
    loginBtnState,
  } = useNavItemsStore();

  const handleLogout = () => {
    loginBtnState(true);
    removeToken();
    router.push("/");
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const handleClose = () => {
    setIsDrawerOpen(false);
  };

  // const [wishlistCount, setWishlistCount] = useState(0);
  // const fetchWishlistCount = async () => {
  //   try {
  //     const response = await getWishlist();
  //     setWishlistCount(response.length);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchWishlistCount();
  // }, []);

  const showLoginOrProfile =
    showLogin !== null && showLogin ? (
      <a
        href="/auth"
        className="flex justify-center items-center"
        aria-label="Login"
      >
        <HomeBrownButton
          title="Login"
          classes="px-5 lg:block hidden bg-white hover:bg-white/90 text-lightRed"
        />
        <User size={25} className="lg:hidden  block" />
      </a>
    ) : (
      <div className=" flex items-center ">
        <UserDropDown
          handleClick={() => {
            router.push("/profile");
          }}
          handleLogout={handleLogout}
        />
      </div>
    );

  useEffect(() => {
    initNavItems(pageType, needKey);
  }, []);

  return (
    <div>
      <header
        className={`   
    z-[999] w-full  bg-lightRed lg:py-4 py-2 lg:px-10 px-4  fixed top-0 lg:block   shadow-header-mobile`}
      >
        <div className="flex justify-between items-center">
          <div className="sm:hidden flex items-center relative ">
            {router?.query?.slug && (
              <button
                onClick={() => router.back()}
                className="hamburger-button py-2 rounded-md focus:outline-none focus:bg-gray-100"
                aria-label="Back"
              >
                <ArrowLeft />
              </button>
            )}
          </div>
          <div className="flex items-center lg:justify-between justify-between gap-4 w-full">
            <a
              href={logoUrlRedirect}
              aria-label="Shaadi Vines"
              className="relative"
            >
              <Logo bgRed={true} />
            </a>

            <div>
              <DesktopNavItemsSV
                navItems={navItems}
                showLoginOrProfile={showLoginOrProfile}
                router={router}
                pageType={pageType}
              />
            </div>
            <div className="sm:hidden flex items-center relative ">
              {/* WishlistIcon */}
              <div className="relative">
                <img
                  src="/assets/wishlist.svg"
                  className="size-6 mr-2"
                  onClick={() => router.push("/wishlist")}
                />
                <span className="absolute -top-2 bg-white -right-0 text-lightRed rounded-full size-[.90rem] flex items-center justify-center font-Jost text-[10px]  m-auto">
                {wishlistCount}
                </span>
              </div>
              <button
                onClick={toggleDrawer}
                className="hamburger-button p-2 rounded-md focus:outline-none focus:bg-gray-100 "
                aria-label="Open Menu"
              >
                <Menu color="#fff" />
              </button>
            </div>
          </div>
          {isDrawerOpen && (
            <div
              className="absolute top-0 left-0 z-[2] w-screen h-screen bg-black
                 opacity-10 animate-showOverlay   ease-in-out duration-300 transform "
              onClick={handleClose}
            ></div>
          )}
          {isDrawerOpen !== null && (
            <aside
              id="default-sidebar"
              className={`fixed top-0 right-0 z-[9999]  transition-transform  
      ease-in-out duration-300 transform 
      ${
        isDrawerOpen
          ? "translate-x-0 xs:translate-x-0 xs:static xs:inset-0"
          : "translate-x-full "
      }
    `}
              aria-label="Sidebar"
            >
              <div className=" px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800 w-screen h-screen relative z-[9999]">
                <MobileNavItemsSV
                  navItems={navItems}
                  router={router}
                  showDropDown={showDropDown}
                  setShowDropDown={setShowDropDown}
                  toggleDrawer={toggleDrawer}
                  showLogin={showLogin}
                  pageType={pageType}
                  handleClose={handleClose}
                />
              </div>
            </aside>
          )}
        </div>
        {/* <div className="lg:hidden">{showLoginOrProfile}</div> */}
      </header>

      <ExpiryContainerSV
        daysLeft={daysLeft}
        trialDate={trialDate}
        pageType={pageType}
      />
    </div>
  );
}

export default NewHeaderSV;
