import { ChevronDown, ChevronRight } from "lucide-react";
import { DropdownMenuLabel } from "../ui/dropdown-menu";

import VideoItems from "../../constants/header/video-nav.json";

function renderDropdownItems(childData, router) {
  return (
    <div
      className=" mt-2 transform scale-0 group-hover/child:scale-100 hidden transition duration-300 w-[180px]
      ease-in-out absolute group-hover/child:block -top-4 left-[23px] z-[999] bg-white rounded-lg shadow-lg p-2">
      {childData?.children?.map((itemDropdown, index) => {
        return (
          <DropdownMenuLabel key={index}>
            <a
              aria-label={itemDropdown?.name}
              className={`lg:text-base md:text-sm cursor-pointer  hover:text-lightRed font-normal 
                ${
                  router.pathname === itemDropdown?.uri
                    ? "text-lightRed"
                    : "text-black"
                }
                  `}
              href={itemDropdown?.uri}>
              {itemDropdown?.name}
            </a>
          </DropdownMenuLabel>
        );
      })}
    </div>
  );
}

function DesktopNavItemsSV({ navItems, showLoginOrProfile, router }) {
  return (
    <div className="hidden sm:flex lg:gap-10 items-center">
      <div className="flex items-center justify-between gap-4">
        <div className={`hidden sm:flex lg:gap-10 items-center  `}>
          {navItems
            .filter((item) => item && item.id)
            ?.map((item, index) => (
              <div key={index}>
                {item.isDropdown ? (
                  // CTREATE DROPDOWN
                  <div className="group">
                    <span className="flex  items-center lg:text-lg md:text-base cursor-pointer bg-transparent  hover:bg-transparent text-white  hover:text-white/80 font-normal">
                      {item?.title}
                      <ChevronDown className="ml-2" size={20} />
                    </span>
                    <div
                      className="  mt-2 transform scale-0 group-hover:scale-100 
              transition duration-300 ease-in-out
            absolute group-hover:block top-12 z-[999] bg-white rounded-lg shadow-lg p-2">
                      {item?.dropdownData?.map((itemDropdown, index) => {
                        return (
                          <DropdownMenuLabel key={index}>
                            <div className="flex lg:text-base md:text-sm cursor-pointer group/child hover:text-white/80  font-normal">
                              <a
                                aria-label="Video Invitation"
                                className={` hover:text-white/80 group-hover/child:text-lightRed ${
                                  router.pathname === itemDropdown?.url
                                    ? "text-lightRed"
                                    : "text-black"
                                }`}
                                href={itemDropdown?.url}>
                                {itemDropdown?.title}
                              </a>
                              {itemDropdown?.title === "Video Invitation" && (
                                <div className=" relative flex items-center ">
                                  <ChevronRight className="ml-2" size={18} />
                                  {renderDropdownItems(VideoItems, router)}
                                </div>
                              )}
                              {/* {itemDropdown?.title === "PDF Invitation" && (
                              <div>
                                <ChevronRight className="ml-2" size={18} />
                                {renderDropdownItems(PDFItems, router)}
                              </div>
                            )} */}
                            </div>
                          </DropdownMenuLabel>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <a
                    aria-label="Home"
                    className={`lg:text-lg md:text-base cursor-pointer  hover:text-white/80 font-normal
                  ${
                    router.pathname === item?.url
                      ? "text-lightRed"
                      : "text-white"
                  }
                  `}
                    href={item?.url}>
                    {item?.title}
                  </a>
                )}
              </div>
            ))}
        </div>
      </div>

      {showLoginOrProfile}
    </div>
  );
}

export default DesktopNavItemsSV;
